






























import { Component, Prop, Vue } from "vue-property-decorator";
import CalendarioSeleccionableFestivos from "@/views/Fichaje/CalendarioSeleccionableFestivos.vue";
import tipos_fichajeModule from "@/store/modules/tipos_fichaje-module";
import { CalendarDay } from "@/components/Cards/CalendarDay";
import calendario_laboralModule from "@/store/modules/calendario_laboral-module";
import { calendario_laboral } from "@/shared/dtos/calendario_laboral";
import { calendario_laboral_dia } from "@/shared/dtos/calendario_laboral_dia";
import { MessageService } from "@/shared/services/message-service";
import { UtilsNumber } from "@/utils/utils-number";
import { Usuario } from "@/shared/dtos/usuario";

@Component({
  components: {
    CalendarioSeleccionableFestivos,
  },
})
export default class CalendarioLaboralEmpleados extends Vue {
  @Prop() usuarioFormulario!: Usuario;
  public CalendarDaysSelected: CalendarDay[] = [];
  public calendario = new calendario_laboral();
  public preparado = false;
  public CalendarDaysSelectedMoviemientosFestivos: CalendarDay[] = [];
  async created() {
    await tipos_fichajeModule.gettipos_fichajes();
    // Cargar el calendario laboral de la empresa
    await calendario_laboralModule.getcalendario_laborales();
    // Cargar el calendario laboral del empleado
    await calendario_laboralModule.getcalendario_laboral_empleado(
      this.usuarioFormulario.id
    );
    this.calendario = calendario_laboralModule.calendario_laboral;
    var hay_festivos_empleado = this.calendario.id > 0;
    if (!hay_festivos_empleado) {
    }

    // Cargar los festivos del empleado
    this.calendario.dias_festivos.forEach((dia) => {
      var date = new Date(dia.fecha);
      this.CalendarDaysSelected.push(
        new CalendarDay({
          CompleteDate: date,
          Color: tipos_fichajeModule.tipos_fichajes.find(
            (x) => x.id === dia.id_tipo_fichaje
          )!.color,
          activo: true,
          Mouth: date.getMonth() + 1,
          Year: date.getFullYear(),
          Day: date.getDate(),
          CssClass: "active noselect",
          anomesdia: `${date.getFullYear()}${UtilsNumber.formatNumberWhitZero(
            date.getMonth() + 1
          )}${UtilsNumber.formatNumberWhitZero(date.getDate())}`,
        })
      );
    });
    //Evitar duplicados
    const existingDays = new Set(
      this.CalendarDaysSelected.map((day) => day.anomesdia)
    );

    calendario_laboralModule.calendario_laborales.forEach((cale) => {
      cale.dias_festivos.forEach((dia) => {
        var date = new Date(dia.fecha);

        var dia_calendario = new CalendarDay({
          CompleteDate: date,
          Color: tipos_fichajeModule.tipos_fichajes.find(
            (x) => x.id === dia.id_tipo_fichaje
          )!.color,
          activo: true,
          Mouth: date.getMonth() + 1,
          Year: date.getFullYear(),
          Day: date.getDate(),
          CssClass: "active noselect",
          anomesdia: `${date.getFullYear()}${UtilsNumber.formatNumberWhitZero(
            date.getMonth() + 1
          )}${UtilsNumber.formatNumberWhitZero(date.getDate())}`,
        });
        if (!existingDays.has(dia_calendario.anomesdia)) {
          this.CalendarDaysSelected.push(dia_calendario);
        }
      });
    });

    this.preparado = true;
  }

  get tipos_fichajes() {
    return tipos_fichajeModule.tipos_fichajes
      .filter((x) => x.festivos_empleado || x.festivos_convenio)
      .map((x) => {
        return { id: x.id, text: x.nombre, color: x.color };
      });
  }
  public diaSeleccionado(DaysSelected: CalendarDay) {
    const index = this.CalendarDaysSelectedMoviemientosFestivos.findIndex(
      (day) => day.anomesdia === DaysSelected.anomesdia
    );

    if (index === -1) {
      // No existe, agregarlo a la lista
      this.CalendarDaysSelectedMoviemientosFestivos.push(DaysSelected);
    } else {
      // Ya existe, eliminarlo de la lista
      this.CalendarDaysSelectedMoviemientosFestivos.splice(index, 1);
    }
  }
  // Método para guardar los cambios del calendario
  public guardarCalendario() {
    var dias_festivos: calendario_laboral_dia[] = [];
    this.calendario.id_usuario = this.usuarioFormulario.id;
    //Para no duplicar registros y que se guarde más ripido vamos a quitar los festivos del convenio

    const uniqueDays = new Set();

    //Primero ponemos los días que se ven en el calendario
    for (let i = 0; i < this.CalendarDaysSelected.length; i++) {
      const dayKey = this.CalendarDaysSelected[i].anomesdia;
      if (!uniqueDays.has(dayKey)) {
        uniqueDays.add(dayKey);
        var tipo_fichaje = tipos_fichajeModule.tipos_fichajes.find(
          (x) => x.color === this.CalendarDaysSelected[i].Color
        );
        if (tipo_fichaje === undefined) {
          tipo_fichaje = tipos_fichajeModule.tipos_fichajes.find(
            (x) => x.nombre === "Trabajo"
          );
        }

        var dia_calendario = new calendario_laboral_dia({
          id: 0,
          fecha: this.CalendarDaysSelected[i].CompleteDate,
          id_calendario_laboral: 0,
          id_tipo_fichaje: tipo_fichaje!.id,
        });

        dias_festivos.push(dia_calendario);
      }
    }
    //Luego ponemos los días de los festivos que se han movido

    for (
      let i = 0;
      i < this.CalendarDaysSelectedMoviemientosFestivos.length;
      i++
    ) {
      const dayKey = this.CalendarDaysSelectedMoviemientosFestivos[i].anomesdia;
      if (!uniqueDays.has(dayKey)) {
        uniqueDays.add(dayKey);
        dias_festivos.push(
          new calendario_laboral_dia({
            id: 0,
            fecha: this.CalendarDaysSelectedMoviemientosFestivos[i]
              .CompleteDate,
            id_calendario_laboral: 0,
            id_tipo_fichaje: tipos_fichajeModule.tipos_fichajes.find(
              (x) => x.nombre === "Trabajo"
            )!.id,
          })
        );
      }
    }

    this.calendario.dias_festivos = dias_festivos;
    calendario_laboralModule
      .guardarcalendario_laboral(this.calendario)
      .then(() => {
        MessageService.toastsuccesful("Calendario Laboral Guardado");
        this.$router.back();
      });
  }
}
