



































































import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import usuarioModule from "@/store/modules/usuario-module";
import { Usuario } from "@/shared/dtos/usuario";
import centro_trabajoModule from "@/store/modules/centro_trabajo-module";
import hist_contratos_empleadoModule from "@/store/modules/hist_contratos_empleado-module";
import rolModule from "@/store/modules/rol-module";
import router from "@/router/router";
import { RouterNames } from "@/router/routernames";
import fichajes_empleadoModule from "@/store/modules/fichajes_empleado-module";
import datos_empresaModule from "@/store/modules/datos_empresa-module";
import CalendarioLaboralEmpleados from "../Calendarios/CalendarioLaboralEmpleados.vue";

@Component({
  components: {
    FullDynamicTable: () => import("../dynamic_tables/full_dynamic_table.vue"),
    HistContratosEmpleadoFormulario: () =>
      import(
        "../hist_contratos_empleado/hist_contratos_empleadoFormulario.vue"
      ),
    CalendarioLaboralEmpleados,
  },
})
export default class usuarioFormulario extends Mixins(HelperDinamicallyForm) {
  public item_pantalla: Usuario = new Usuario();

  public async OnCreated() {
    this.ShowLoading();

    if (this.getparamId().HayParametro) {
      await usuarioModule.getusuario(this.getparamId().Parametro);
      this.item_pantalla = usuarioModule.usuario;
      await hist_contratos_empleadoModule.gethist_contratos_por_empleado(
        this.getparamId().Parametro
      );
      await fichajes_empleadoModule.getfichajes_empleadosByIdUsuario(
        this.item_pantalla.id
      );
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    this.HideLoading();
    await rolModule.getroles();
    await centro_trabajoModule.getcentro_trabajos();
    await datos_empresaModule.getdatos_empresas();
  }

  open_form_contrato(id: string) {
    if (this.EstoyCreado) {
      alert("Guarde el usuario antes de crear un contrato");
      return;
    }
    this.$router.push({
      name: RouterNames.contratos_empleado,
      params: { id: id, id_empleado: this.getparamId().Parametro.toString() },
    });
  }

  async refresh_hist_contratos() {
    await hist_contratos_empleadoModule.gethist_contratos_por_empleado(
      this.getparamId().Parametro
    );
  }

  async refresh_fichajes_empleado() {
    await fichajes_empleadoModule.getfichajes_empleadosByIdUsuario(
      this.getparamId().Parametro
    );
  }

  public CreateNameTabs() {
    this.AddTabName("Ficha");
    this.AddTabName("Contratos");
    this.AddTabName("Fichajes");
    this.AddTabName("Calendario");
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 4, 2);

    this.AddFormItemDataDefaultString(this.item_pantalla.nif, "Nif", "nif", 50)
      .Dimesiones(12, 4, 2)
      .isRequired()
      .CrearValidacion("nie");

    this.AddFormItemDataDefaultString(
      this.item_pantalla.email,
      "Email",
      "email",
      50
    )
      .Dimesiones(12, 6, 4)
      .isRequired()
      .CrearValidacion("email");
    this.AddFormItemDataDefaultString(
      this.item_pantalla.num_seg_social,
      "Número de seguridad social",
      "num_seg_social",
      50
    ).Dimesiones(12, 6, 4);
    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_rol,
      "Rol",
      "id_rol"
    )
      .Dimesiones(12, 6, 4)
      .isRequired()
      .CreateComboSingleCodigoDescripcionNames(rolModule.roles, "nombre", "id");

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.ids_datos_empresa,
      "Empresas",
      "ids_datos_empresa"
    )
      .Dimesiones(12, 6, 4)
      .isRequired()
      .CreateComboMultiple(datos_empresaModule.datos_empresas, "nombre", "id");

    this.AddFormItemDataDefaultString(
      this.item_pantalla.contra,
      "Contraseña",
      "contra"
    )
      .Dimesiones(12, 6, 4)
      .isRequired(this.EstoyCreado)
      .isVisible(this.EstoyCreado);

    this.AddFormItemDataDefaultBoolean(
      this.item_pantalla.cambiar_pwd_entrar,
      "Cambiar contraseña al entrar",
      "cambiar_pwd_entrar",
      1,
      "Cambiar contraseña al entrar",
      "Cambiar contraseña al entrar"
    )
      .Dimesiones(12, 6, 4)
      .isRequired(this.EstoyCreado)
      .isVisible(this.EstoyCreado);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.descripcion,
      "Descripción",
      "descripcion",
      50
    )
      .Dimesiones(12, 4, 2)
      .CreateTextArea();

    this.AddFormItemDataDefaultSlot(
      hist_contratos_empleadoModule.hist_contratos_empleados,
      "Historial de contratos",
      "hist_contratos_empleado",
      2,
      2
    )
      .Dimesiones(12, 12, 12)
      .isVisible(!this.EstoyCreado);

    this.AddFormItemDataDefaultSlot(
      fichajes_empleadoModule.fichajes_empleados,
      "Historial de fichajes",
      "fichajes_empleados",
      2,
      3
    )
      .Dimesiones(12, 12, 12)
      .isVisible(!this.EstoyCreado);

    this.AddFormItemDataDefaultSlot(
      undefined,
      "Calendario laboral",
      "calendario",
      2,
      4
    )
      .Dimesiones(12, 12, 12)
      .isVisible(!this.EstoyCreado);
  }

  public Insertar(object: Usuario) {
    usuarioModule.guardarusuario(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: Usuario) {
    usuarioModule.modificarusuario(object).then(() => {
      this.AllSaveOk(); //guardo correctamente y me cierro
    });
  }
}
